<template>
  <div class="content fs-6 d-flex flex-column flex-column-fluid">
    <div class="toolbar">
      <div class="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
        <div
          class="d-flex flex-column align-items-start justify-content-center flex-wrap me-2"
        >
          <h1 class="text-dark fw-bolder my-1 fs-2">Contacts</h1>
        </div>
        <div class="d-flex align-items-center flex-nowrap text-nowrap py-1">
          <span v-if="contacts" class="badge badge-primary">{{
            contacts.count
          }}</span>
        </div>
      </div>
    </div>
    <div class="post fs-6 d-flex flex-column-fluid">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header border-0 pt-6">
            <div class="card-title">
              <div class="d-flex align-items-center position-relative my-1">
                <span class="svg-icon svg-icon-1 position-absolute ms-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="17.0365"
                      y="15.1223"
                      width="8.15546"
                      height="2"
                      rx="1"
                      transform="rotate(45 17.0365 15.1223)"
                      fill="black"
                    />
                    <path
                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <form @submit.prevent="getAllContacts()">
                  <input
                    v-model="filter.search"
                    type="text"
                    class="form-control form-control-solid w-250px ps-14 me-3"
                    placeholder="Chercher un contact"
                  />
                </form>
                <button
                  v-if="filter.search"
                  type="button"
                  class="btn btn-light-danger"
                  @click.prevent="resetSearch()"
                >
                  Supprimer la recherche
                </button>
              </div>
            </div>
            <div class="card-toolbar">
              <div class="d-flex justify-content-end">
                <div class="me-3 d-flex align-items-center">
                  <input
                    v-model="scrollValue"
                    type="range"
                    min="0"
                    :max="maxScroll"
                    class="form-range"
                    @input="scrollTable"
                  />
                </div>
                <a
                  href="/contacts/form/add"
                  type="button"
                  class="btn btn-primary"
                >
                  <span class="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x="11.364"
                        y="20.364"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(-90 11.364 20.364)"
                        fill="black"
                      />
                      <rect
                        x="4.36396"
                        y="11.364"
                        width="16"
                        height="2"
                        rx="1"
                        fill="black"
                      />
                    </svg>
                  </span>
                  Ajouter un contact
                </a>
              </div>
            </div>
          </div>

          <!-- Loader -->
          <Loader :data="contacts" />

          <div v-if="contacts">
            <div v-if="contacts.count > 0">
              <div class="card-body p-0">
                <div
                  ref="tableResponsiveContacts"
                  class="table-responsive"
                  @scroll="onTableScroll"
                >
                  <table
                    v-if="contacts.results"
                    class="table table-flush align-middle table-row-bordered table-row-solid gy-4"
                  >
                    <div class="h-700px overflow-scroll">
                      <thead
                        style="
                          position: sticky;
                          top: 0;
                          z-index: 1;
                          background-color: white;
                        "
                      >
                        <tr
                          class="text-start fw-bolder fs-6 text-uppercase gs-0"
                        >
                          <th class="min-w-125px ps-9">Nom</th>
                          <th class="min-w-125px ps-9">Prénom</th>
                          <th class="min-w-125px ps-9">Téléphone</th>
                          <th class="min-w-125px ps-9">Mail</th>
                          <th class="min-w-125px ps-9">Promoteur</th>
                          <th class="min-w-125px ps-9">Agence</th>
                          <th class="min-w-125px ps-9">Poste</th>
                          <th class="min-w-125px ps-9">
                            Date du dernier échange
                          </th>
                          <th class="min-w-125px ps-9">
                            Dernière personne à l'avoir contacté
                          </th>
                          <th class="min-w-125px ps-9">
                            Qualité de la relation
                          </th>
                        </tr>
                      </thead>

                      <tbody class="fw-bold">
                        <tr
                          v-for="contact in contacts.results"
                          :key="contact.id"
                          style="cursor: pointer"
                          @click="redirectToContactDetails(contact.id, $event)"
                        >
                          <td class="min-w-125px ps-9" style="max-width: 175px">
                            <router-link
                              :to="'/contacts/' + contact.id"
                              class="text-black"
                              ><span v-if="contact.last_name">
                                {{ contact.last_name }} </span
                              ><span v-else class="text-black-50"
                                >Non renseigné</span
                              >
                            </router-link>
                          </td>
                          <td class="min-w-125px ps-9" style="max-width: 175px">
                            <router-link
                              :to="'/contacts/' + contact.id"
                              class="text-black"
                              ><span v-if="contact.first_name">{{
                                contact.first_name
                              }}</span
                              ><span v-else class="text-black-50"
                                >Non renseigné</span
                              ></router-link
                            >
                          </td>
                          <td class="min-w-125px ps-9" style="max-width: 175px">
                            <router-link
                              :to="'/contacts/' + contact.id"
                              class="text-black"
                              ><span v-if="contact.phone_number">{{
                                contact.phone_number
                              }}</span
                              ><span v-else class="text-black-50"
                                >Non renseigné</span
                              ></router-link
                            >
                          </td>
                          <td class="min-w-125px ps-9" style="max-width: 175px">
                            <router-link
                              :to="'/contacts/' + contact.id"
                              class="text-black"
                              ><span v-if="contact.email">{{
                                contact.email
                              }}</span
                              ><span v-else class="text-black-50"
                                >Non renseigné</span
                              ></router-link
                            >
                          </td>
                          <td class="min-w-125px ps-9" style="max-width: 175px">
                            <router-link
                              :to="'/contacts/' + contact.id"
                              class="text-black"
                              ><span v-if="contact.promoter__name">{{
                                contact.promoter__name
                              }}</span
                              ><span v-else class="text-black-50"
                                >Non renseigné</span
                              ></router-link
                            >
                          </td>
                          <td class="min-w-125px ps-9" style="max-width: 175px">
                            <router-link
                              :to="'/contacts/' + contact.id"
                              class="text-black"
                              ><span v-if="contact.agency__name">{{
                                contact.agency__name
                              }}</span
                              ><span v-else class="text-black-50"
                                >Non renseigné</span
                              ></router-link
                            >
                          </td>
                          <td class="min-w-125px ps-9" style="max-width: 175px">
                            <router-link
                              :to="'/contacts/' + contact.id"
                              class="text-black"
                              ><span v-if="contact.position">{{
                                contact.position
                              }}</span
                              ><span v-else class="text-black-50"
                                >Non renseigné</span
                              ></router-link
                            >
                          </td>
                          <td class="min-w-125px ps-9" style="max-width: 175px">
                            <router-link
                              :to="'/contacts/' + contact.id"
                              class="text-black"
                              ><span v-if="contact.last_followup_date">
                                {{
                                  getMomentDate(contact.last_followup_date)
                                }}</span
                              ><span v-else class="text-black-50"
                                >Non renseigné</span
                              ></router-link
                            >
                          </td>
                          <td class="min-w-125px ps-9" style="max-width: 175px">
                            <router-link
                              :to="'/contacts/' + contact.id"
                              class="text-black"
                              ><span v-if="contact.last_followup_author">
                                {{ contact.last_followup_author }}</span
                              ><span v-else class="text-black-50"
                                >Non renseigné</span
                              ></router-link
                            >
                          </td>
                          <td class="min-w-125px ps-9" style="max-width: 175px">
                            <router-link
                              :to="'/contacts/' + contact.id"
                              class="text-dark"
                            >
                              <span
                                v-if="contact.relation_quality"
                                class="ms-2 badge bg-light-warning text-black"
                              >
                                {{ contact.relation_quality }}
                                <i
                                  class="bi bi-star-fill text-warning h6"
                                ></i></span
                              ><span v-else class="text-black-50"
                                >Non renseigné</span
                              ></router-link
                            >
                          </td>
                        </tr>
                      </tbody>
                    </div>
                  </table>
                </div>
              </div>
              <div v-if="contacts" class="card-footer">
                <div
                  class="row justify-content-center justify-content-sm-between align-items-sm-center"
                >
                  <TableFooter
                    v-if="contacts"
                    :all-data="contacts"
                    :default-size="30"
                    :page="filter.page"
                    :has-query="true"
                    @selectedSize="getSizeSelected"
                    @selectedPage="getPageSelected"
                    @nextPage="showNextContacts(contacts.next)"
                    @previousPage="showPreviousContacts(contacts.previous)"
                    @change="getAllContacts"
                  />
                </div>
              </div>
            </div>
            <div v-else class="card-body ms-1">
              <div class="alert alert-secondary">
                <div class="d-flex flex-column">
                  <span class="mb-1 text-dark">Aucun contact enregistré</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/Loader";
import TableFooter from "@/components/TableFooter";
import store from "@/store";
import moment from "moment";

export default {
  name: "Contacts",
  components: {
    Loader,
    TableFooter,
  },
  data() {
    return {
      filter: {
        search: "",
        size: "",
        defaultSize: 30,
        page: "",
      },
      scrollValue: 0,
      maxScroll: 100,
    };
  },
  computed: {
    ...mapGetters(["contacts"]),
  },
  methods: {
    ...mapActions(["getContacts"]),
    ...mapActions(["nextPaginate"]),
    ...mapActions(["previousPaginate"]),
    // Get contacts
    getAllContacts() {
      this.getContacts(this.filter);
      this.contactsData = this.contacts;
    },
    redirectToContactDetails(contactId) {
      if (event.ctrlKey || event.metaKey) {
        const newTab = window.open("", "_blank");
        newTab.location.href = "/contacts/" + contactId;
      } else {
        this.$router.push("/contacts/" + contactId);
      }
    },
    async getPageSelected(res) {
      this.filter.page = res.currentPage;
    },
    async getSizeSelected(res) {
      this.filter.size = res.sizeSelected;
    },
    // Search
    resetSearch() {
      this.filter.search = "";
      this.getAllContacts();
    },
    // Paginations
    async showNextContacts(url) {
      this.contactsData = await this.nextPaginate(url);
      store.commit("contacts", {
        ...this.contactsData,
      });
    },
    async showPreviousContacts(url) {
      this.contactsData = await this.previousPaginate(url);
      store.commit("contacts", {
        ...this.contactsData,
      });
    },
    // Plugins
    getMomentDate(value) {
      return moment(value).locale("FR").format("DD/MM/YYYY");
    },
    scrollTable() {
      const tableContainer = this.$refs.tableResponsiveContacts;
      if (tableContainer) {
        const scrollPercentage = this.scrollValue / this.maxScroll;
        const maxScrollAmount =
          tableContainer.scrollWidth - tableContainer.clientWidth;
        const scrollAmount = maxScrollAmount * scrollPercentage;
        tableContainer.scrollLeft = scrollAmount;
      }
    },
    onTableScroll() {
      const tableContainer = this.$refs.tableResponsiveContacts;
      if (tableContainer) {
        const maxScrollAmount =
          tableContainer.scrollWidth - tableContainer.clientWidth;
        if (maxScrollAmount > 0) {
          const scrollPercentage = tableContainer.scrollLeft / maxScrollAmount;
          this.scrollValue = scrollPercentage * this.maxScroll;
        }
      }
    },
  },
  // eslint-disable-next-line vue/order-in-components
  mounted() {
    const query = { ...this.$route.query };
    if (query.page && query.size) {
      this.filter.page = query.page;
      this.filter.size = query.size;
    }
    this.getAllContacts();
  },
};
</script>
